:root {
    --color-primary: #FDE0B5;
    --color-secondary: #FBB54F;
    --color-text: #941528;
}

@font-face {
    font-family: "Inter-Regular";
    src: url("./assets/fonts/inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inkfree";
    src: url("./assets/fonts/inkfree/Inkfree.ttf");
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    border-radius: 1.5rem;
}

::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 1.5rem;
}

body {
    font-family: "Inter-Regular" !important;
}

.custom_btn {
    border: 0;
    border-radius: 12px;
    background-color: var(--color-primary);
    font-size: 1.25rem;
    padding: 2px 20px;
    color: black !important;
    text-decoration: none;
    margin-left: 20px;
    user-select: none;
    cursor: pointer;
}

.nav {
    height: fit-content;
    padding-bottom: 3.5rem !important;
    display: flex;
    justify-content: end;
    padding-top: 1rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important
}

.header {
    background-color: var(--color-primary);
}

.right_side {
    background-image: url("./assets/images/banner.jpg");
    background-size: unset;
    background-repeat: no-repeat;
    background-position: right;
}

.logo {
    width: 8rem;
    margin-top: -3rem;
    margin-left: 45px;
}

.header_title {
    font-weight: 600;
    color: var(--color-text);
    font-size: 9.063rem;
}

.header_sub_title {
    color: var(--color-text);
    font-size: 4.813rem;
}

.header_p {
    font-size: 2rem
}

.title {
    color: var(--color-text);
    font-family: "Inkfree";
    font-size: 4rem;
}

.search_input {
    margin-top: 2rem;
    background-color: var(--color-primary);
    height: 4rem;
    width: 100%;
    padding-left: 80px;
    border-radius: 28px;
    padding-left: 6rem;
    border: 0;
}

.w-fit {
    width: fit-content !important;
}

.h-fit {
    height: fit-content;
}

.confirm_form {
    width: 75%;
}

.search_input:focus-visible,
.search_input:focus,
.search_input:active {
    border: 0;
    outline: 0;
}

.input {
    display: block;
    width: 15rem;
    background-color: var(--color-primary);
    /* height: 3rem; */
    border-radius: 15px;
    font-size: 24px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon_container {
    position: absolute;
    width: fit-content;
    width: 5rem;
    border-radius: 5rem;
    background-color: var(--color-secondary);
    height: fit-content;
    bottom: -4px;
}

.icon_container svg {
    padding: 0.8rem 0.8rem;
    margin-top: -0.5rem;
}

.button {
    border-radius: 15px;
    padding: 0.5rem 3rem;
    width: fit-content;
    display: block;
    text-align: center;
    height: fit-content;
    font-size: 24px;
    background-color: var(--color-secondary);
    text-decoration: none;
    color: black;
}

.button:disabled {
    cursor: default;
    background-color: #9b9b9b;
    color: white;
}

.footer {
    background-color: var(--color-primary);
}

.footer .text {
    font-size: 24px;
}

.logo_footer {
    width: 100%;
}

#sign_btn:has(.login_loader) {
    padding: 0;
}

button:disabled {
    cursor: default;
}

i {
    font-size: 30px;
    width: 10%;
}

.checking .header {
    text-align: center;
    height: fit-content;
}

.checking .header_title {
    margin-top: -3rem;
    margin-left: 28%;
}

.checking .header_sub_title {
    font-size: 4.813rem;
}

.header_p {
    font-size: 2rem
}

.total_pirce {
    display: flex;
    justify-content: space-between;
    /* width: 20rem; */
    width: 70%;
    background-color: var(--color-primary);
    /* height: 3rem; */
    border-radius: 15px;
    font-size: 24px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.input {
    width: 60%;
    background-color: var(--color-primary);
    /* height: 3rem; */
    border-radius: 15px;
    border: 0;
    font-size: 24px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.checkbox_label {
    cursor: pointer;
}

label:not(.checkbox_label) {
    font-size: 24px;
    margin-right: 1rem;
    width: 11rem;
}



.icon_container svg {
    padding: 0.8rem 0.8rem;
    margin-top: -0.5rem;
}

.button {
    border: 0;
    cursor: pointer;
    border-radius: 15px;
    padding: 0.5rem 3rem;
    width: fit-content;
    display: block;
    text-align: center;
    height: fit-content;
    font-size: 24px;
    background-color: var(--color-secondary);
    text-decoration: none;
    color: black;
}

i {
    font-size: 30px;
    width: 10%;
}

input[type=checkbox] {
    width: 1.1rem;
    height: 1.1rem;
}

/* label:has(.d-flex) {
    font-size: 24px;
    margin-right: 1rem;
    width: 11rem;
} */

.category {
    font-weight: 600;
    color: var(--color-text);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-size: 2.25rem;
}

.thanks .header {
    text-align: center;
    height: fit-content;
}

.thanks .header_title {
    margin-top: -3rem;
    margin-left: 28%;
}

/* login */
.login {
    /* background-color: #080710; */
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
}

.login .form {
    /* height: 520px; */
    width: 400px;
    /* width: 50%; */
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    padding: 50px 35px;
}

.login .form * {
    color: black;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.login .form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.login label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.login input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: #80808047;
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

.login ::placeholder {
    color: black;
}

.login button {
    margin-top: 30px;
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color-secondary);
    color: white !important;
}

.login .error {
    text-align: center;
    color: red;
}

.login .logo {
    margin: auto;
    width: 11rem;
    display: flex;
}

.login .logo_container {
    width: fit-content;
    margin: auto;
    display: flex;
    gap: 10px;
}

/* admin */
.orders_page {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 85%;
}

.cards_container {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.header_admin {
    width: 100%;
    padding: 20px 20px;
    text-align: center;
    font-size: 2rem;
    /* margin-top: 10px; */
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: var(--color-primary);
    color: var(--color-text);
}

.card {
    color: white !important;
    background-color: var(--color-secondary) !important;
    padding: 30px 25px 25px 25px;
}

.card_header {
    font-size: 1.5rem;
    text-align: center;
}

.card_body {
    padding-left: 1rem !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
}

.card_body li div {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    gap: 5px;
}



.card_footer {
    margin: auto;
}

.btn_complete {
    background-color: var(--color-primary) !important;
    color: var(--color-text);
    margin: auto;
}

.btn_complete:hover {
    background-color: #4bfb4b !important;
    color: white;
    margin: auto;
}

.btn_complete:disabled.completed {
    border: 0;
    cursor: default;
    background-color: #4bfb4b !important;
    color: white;
}

.search_container {
    position: relative !important;
    display: flex !important;
    width: 50% !important;
    margin-left: auto !important;
}

@media screen and (max-width:1200px) {
    .cards_container {
        grid-template-columns: 1fr 1fr;
    }

    .header_title {
        font-size: 5.063rem;
    }

    .header_sub_title {
        font-size: 2.813rem;
    }

    .header_p {
        font-size: 1.5rem
    }

}


@media screen and (max-width:991px) {
    .closedSign_overlay .logo {
        width: 30% !important;
    }

    .closedSign_container {
        width: 50% !important;
    }

    .checking .header_title {
        /* font-size: 3rem;
        margin-top: -2rem; */
        font-size: 2.5rem;
        margin-top: -1.2rem !important;
        margin: auto;
    }

    .checking .header_sub_title {
        font-size: 2.813rem
    }

    .thanks .header_title {
        font-size: 3rem;
        margin-top: -2rem;
    }

    .title {
        font-size: 2rem;
    }

    .text,
    .button,
    .input,
    label,
    .total_pirce {
        font-size: 16px !important;
    }

    .nav {
        min-height: 90px;
        padding-top: 0.5rem !important;
    }
}

@media screen and (max-width:765px) {
    .closedSign_overlay .logo {
        width: 40% !important;
    }

    .closedSign_container {
        width: 60% !important;
    }

    .cards_container {
        grid-template-columns: 1fr;
    }

    .confirm_form {
        width: 100%;
        margin: auto;
    }

    .right_side {
        /* background-image: none; */
        width: 100%;
        padding: 0 !important;
        height: fit-content;
    }

    .right_side img {
        height: 65%;
        width: 100%;
    }

    .nav {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .search_container {
        width: 75% !important;
    }
}

@media screen and (max-width:560px) {
    .login .form {
        width: 90%;
    }

    .total_pirce {
        width: 80%;
    }
}


/* loader */
/* HTML: <div class="loader"></div> */
html:has(.loader),
html:has(#cooking) {
    overflow: hidden;
}

.loader_overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.loader {
    --c1: #673b14;
    --c2: #f8b13b;
    width: 40px;
    height: 80px;
    border-top: 4px solid var(--c1);
    border-bottom: 4px solid var(--c1);
    background: linear-gradient(90deg, var(--c1) 2px, var(--c2) 0 5px, var(--c1) 0) 50%/7px 8px no-repeat;
    display: grid;
    overflow: hidden;
    animation: l5-0 2s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    width: 75%;
    height: calc(50% - 4px);
    margin: 0 auto;
    border: 2px solid var(--c1);
    border-top: 0;
    box-sizing: content-box;
    border-radius: 0 0 40% 40%;
    -webkit-mask:
        linear-gradient(#000 0 0) bottom/4px 2px no-repeat,
        linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    background:
        linear-gradient(var(--d, 0deg), var(--c2) 50%, #0000 0) bottom /100% 205%,
        linear-gradient(var(--c2) 0 0) center/0 100%;
    background-repeat: no-repeat;
    animation: inherit;
    animation-name: l5-1;
}

.loader::after {
    transform-origin: 50% calc(100% + 2px);
    transform: scaleY(-1);
    --s: 3px;
    --d: 180deg;
}

@keyframes l5-0 {
    80% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(0.5turn)
    }
}

@keyframes l5-1 {

    10%,
    70% {
        background-size: 100% 205%, var(--s, 0) 100%
    }

    70%,
    100% {
        background-position: top, center
    }
}

/* loader cooking */
#cooking {
    position: relative;
    margin: 0 auto;
    top: 0;
    width: 75vh;
    height: 75vh;
    overflow: hidden;
}

#cooking #area {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform-origin: 15% 60%;
    animation: flip 2.1s ease-in-out infinite;
}

#cooking #area #sides {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 15% 60%;
    animation: switchSide 2.1s ease-in-out infinite;
}

#cooking #area #sides #handle {
    position: absolute;
    bottom: 18%;
    right: 80%;
    width: 35%;
    height: 20%;
    background-color: transparent;
    border-top: 1vh solid #FBB54F;
    border-left: 1vh solid transparent;
    border-radius: 100%;
    transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}

#cooking #area #sides #pan {
    position: absolute;
    bottom: 20%;
    right: 30%;
    width: 50%;
    height: 8%;
    background-color: #FBB54F;
    border-radius: 0 0 1.4em 1.4em;
    transform-origin: -15% 0;
}

#cooking #area #pancake {
    position: absolute;
    top: 24%;
    width: 100%;
    height: 100%;
    transform: rotateX(85deg);
    animation: jump 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry {
    position: absolute;
    bottom: 26%;
    right: 37%;
    width: 40%;
    height: 45%;
    background-color: #FBB54F;
    box-shadow: 0 0 3px 0 #FBB54F;
    border-radius: 100%;
    transform-origin: -20% 0;
    animation: fly 2.1s ease-in-out infinite;
}

#cooking .bubble {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0.25vh #4d4d4d;
    opacity: 0;
}

#cooking .bubble:nth-child(1) {
    margin-top: 2.5vh;
    left: 58%;
    width: 2.5vh;
    height: 2.5vh;
    background-color: #FBB54F;
    animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#cooking .bubble:nth-child(2) {
    margin-top: 3vh;
    left: 52%;
    width: 2vh;
    height: 2vh;
    background-color: #FBB54F;
    animation: bubble 2s ease-in-out 0.35s infinite;
}

#cooking .bubble:nth-child(3) {
    margin-top: 1.8vh;
    left: 50%;
    width: 1.5vh;
    height: 1.5vh;
    background-color: #FBB54F;
    animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(4) {
    margin-top: 2.7vh;
    left: 56%;
    width: 1.2vh;
    height: 1.2vh;
    background-color: #FBB54F;
    animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(5) {
    margin-top: 2.7vh;
    left: 63%;
    width: 1.1vh;
    height: 1.1vh;
    background-color: #FBB54F;
    animation: bubble 1.6s ease-in-out 1s infinite;
}


@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity: 0.25;
    }

    50% {
        transform: scale(1.2, 1);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0.25;
    }
}

@keyframes flip {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(-27deg);
    }

    30%,
    50% {
        transform: rotate(0deg);
    }

    55% {
        transform: rotate(27deg);
    }

    83.3% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes switchSide {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes jump {
    0% {
        top: 24;
        transform: rotateX(85deg);
    }

    25% {
        top: 10%;
        transform: rotateX(0deg);
    }

    50% {
        top: 30%;
        transform: rotateX(85deg);
    }

    75% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(85deg);
    }
}

@keyframes fly {
    0% {
        bottom: 26%;
        transform: rotate(0deg);
    }

    10% {
        bottom: 40%;
    }

    50% {
        bottom: 26%;
        transform: rotate(-190deg);
    }

    80% {
        bottom: 40%;
    }

    100% {
        bottom: 26%;
        transform: rotate(0deg);
    }
}

@keyframes bubble {
    0% {
        transform: scale(0.15, 0.15);
        top: 80%;
        opacity: 0;
    }

    50% {
        transform: scale(1.1, 1.1);
        opacity: 1;
    }

    100% {
        transform: scale(0.33, 0.33);
        top: 60%;
        opacity: 0;
    }
}

/* loging loader */
.spanner {
    display: block;
    width: 3rem;
    margin: auto;
}

.h-300px {
    height: 300px;
    display: flex;
}

.closedSign_overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    flex-direction: column;
}

.closedSign_overlay .logo {
    position: relative;
    margin: auto;
    width: 20%;
    margin-bottom: 2rem;
}

.closedSign_container {
    margin: auto;
    height: fit-content;
    width: 40%;
    box-shadow: 5px 5px 26px -2px black;
    border-radius: 10px;
}

.closed_btn {
    background-color: var(--color-text) !important;
    cursor: not-allowed !important;
}

a {
    text-decoration: none !important;
}

.button_sm {
    cursor: pointer;
    width: fit-content;
    text-align: center;
    font-size: 14px;
    outline: none;
    text-decoration: none;
    background-color: var(--color-primary);
    color: black;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 10px;
}

.filter {
    cursor: pointer;
    width: fit-content;
    text-align: center;
    background-color: var(--color-primary);
    color: black;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 10px;
}

.filter.active {
    background-color: var(--color-text);
    color: white;
}

.empty_data {
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: fit-content;
    background-color: var(--color-primary);
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    margin: auto;
    grid-column-start: 1;
    grid-column-end: 4;
}

.contact_icons {
    padding: 0.2rem 0.6rem 0.2rem 0.6rem;
    border-radius: 25px;
    background-color: white;
    width: fit-content;
    position: absolute;
    right: 0.4rem;
    top: 0.3rem;
}

.contact_icons svg {
    color: #000;
    font-size: 1.3rem;
    cursor: pointer;
}

.contact_icons svg.whatsapp {
    font-size: 1.8rem;
    color: #0cc143;
}

.checkbox_label {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    gap: 0.5rem;
}

.title_dish {
    font-weight: bold;
}

.checkbox_label p {
    font-size: 14px;
}

.checkbox_label .price {
    width: fit-content;
    text-wrap: nowrap;
}

input[type="number"] {
    width: 50px;
}